import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

const TopicsCard = ({
  topic,
  speaker,
  title,
  company,
  description,
  url,
  background,
}) => {
  return (
    <div
      className='topicsCard'
      style={{ backgroundImage: `url(${background})` }}
    >
      <h2>{topic}</h2>
      <h3>{speaker}</h3>
      <h4>
        {title} at <strong>{company}</strong>
      </h4>
      <p>{description}</p>
      <Link to={url} className='button'>
        LEARN MORE
      </Link>
    </div>
  );
};

TopicsCard.propTypes = {
  topic: PropTypes.string,
  speaker: PropTypes.string,
  description: PropTypes.string,
  title: PropTypes.string,
  company: PropTypes.string,
  url: PropTypes.string,
  background: PropTypes.string,
};

export default TopicsCard;
