import React from 'react';
import TopicsCard from './TopicCard';

import digitalWorkforce from '../assets/images/topics/digital_workforce.png';
import customer from '../assets/images/topics/customer.png';
import innovation from '../assets/images/topics/innovation.png';
import assets from '../assets/images/topics/assets.png';

import bg from '../assets/images/shutterstock-1724964856.png';

const Topics = () => {
  return (
    <div
      className='topics'
      style={{ backgroundImage: `url(${bg})` }}
      id='speakers'
    >
      <h3>
        Discover <span>the topics and speakers</span>
      </h3>
      <div className='topicsContainer'>
        <TopicsCard
          topic='Digital Workforce'
          speaker='John Johanson'
          title='Innovation manager'
          company='Google'
          url='/topic/digital-workforce'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
          background={digitalWorkforce}
        />
        <TopicsCard
          topic='Customer'
          speaker='John Johanson'
          title='Innovation manager'
          company='Google'
          url='/topic/customer'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
          background={customer}
        />
        <TopicsCard
          topic='Innovation'
          speaker='John Johanson'
          title='Innovation manager'
          company='Google'
          url='/topic/innovation'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
          background={innovation}
        />
        <TopicsCard
          topic='Assets'
          speaker='John Johanson'
          title='Innovation manager'
          company='Google'
          url='/topic/assets'
          description="Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy."
          background={assets}
        />
      </div>
    </div>
  );
};

export default Topics;
