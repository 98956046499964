import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from 'components/Layout';

import bgWorld from 'assets/images/group-3.png';
import bgAct from 'assets/images/group-2-copy.png';
import bgPart from 'assets/images/group-8.png';
import path4 from 'assets/images/path-4.svg';
import path4Copy from 'assets/images/path-4-copy.svg';
// import img_gatsby from 'assets/images/gatsby-astronaut.png';
import VideoSlide from '../components/VideoSlide';
import { Col, Row, Container } from 'react-bootstrap';
import Topics from '../components/Topics';
import CTA from '../components/CTA';

const IndexPage = () => {
  return (
    <Layout pageName='home'>
      <Helmet>
        <title>EY - The enlightenment session</title>
      </Helmet>
      <VideoSlide />
      <Container>
        <div className='about' id='about'>
          <h1>
            The <span>enlightenment</span> session
          </h1>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has <br /> been the industry's standard dummy
            text ever since the 1500s, when an unknown printer
            <br /> took a galley of type and scrambled it to make a type
            specimen book.
          </p>
          {/* <a
            href='mailto:info@vodw.be?subject=Contact me about the enlightenment sessions'
            className='button'
          >
            Get involved
          </a> */}
        </div>
      </Container>
      <div className='quote'>
        <h2>
          “Lorem Ipsum is simply dummy text of{' '}
          <span>the printing and typesetting industry.”</span>
        </h2>
        <h4>John Johanson</h4>
        <h5>
          Innovation manager at <strong>Google</strong>
        </h5>
      </div>
      <div className='world'>
        <img src={bgWorld} alt='bg World' className='bg' />
        <Container>
          <Row>
            <Col></Col>
            <Col>
              <h2>
                The world <span>is changing</span>
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer. Lorem Ipsum
                has been the industry's standard dummy text ever since the
                1500s, when an unknown printer.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={path4} alt='path4' />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='act'>
        <img src={bgAct} alt='bg Act Now' className='bg' />
        <Container>
          <Row>
            <Col>
              <h2>
                We have to <span>act now</span>
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer. Lorem Ipsum
                has been the industry's standard dummy text ever since the
                1500s, when an unknown printer.
              </p>
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <img src={path4Copy} alt='path4' />
            </Col>
          </Row>
        </Container>
      </div>
      <div className='part'>
        <img src={bgPart} alt='bg Part Now' className='bg' />
        <Container>
          <Row>
            <Col></Col>
            <Col>
              <h2>
                Be part of <span>the movement</span>
              </h2>
              <p>
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer. Lorem Ipsum
                has been the industry's standard dummy text ever since the
                1500s, when an unknown printer.
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <img src={path4} alt='path4' />
            </Col>
          </Row>
        </Container>
      </div>
      <Topics />
      <CTA />
    </Layout>
  );
};

export default IndexPage;
